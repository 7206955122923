<template>
  <v-container grid-list-md>
    <v-row>
      <v-col cols="12" sm="10" md="8" lg="6" offset-sm="1" offset-md="2" offset-lg="3">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>Probasco Guest Room Request</v-toolbar-title>
          </v-toolbar>
          <v-card-text>The Probasco visitor's center has a single guest room available to rent for a family that is visiting the college, whether they be alumni, parents, pastors, or any other campus visitor. Use the button below to access the reservation form where you can reserve the guest room for up to 3 nights. If you have already made a reservation and want to review or modify that reservation, click the "Manage My Reservation" link below.</v-card-text>
          <v-card-actions :style="isXS ? 'display:block': ''">
            <v-menu v-if="isEmployee">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" text>Make a Reservation</v-btn>
              </template>
              <v-list dense>
                <v-list-item to="/accommodations/internal">
                  <v-list-item-content>
                    <v-list-item-title>Departmental Reservation</v-list-item-title>
                    <v-list-item-subtitle>Charges to your department; need an ORGN code</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/accommodations/request">
                  <v-list-item-content>
                    <v-list-item-title>Personal Reservation</v-list-item-title>
                    <v-list-item-subtitle>Pay through PayPal</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn v-else text to="/accommodations/request">Make a Reservation</v-btn>
            <v-spacer></v-spacer>
            <v-btn disabled text to="/accommodations/manage">Manage My Reservation</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { computed } from '@vue/composition-api'
export default {
  setup (props, { root }) {
    const isEmployee = computed(() => {
      if (!root.$store.state.loggedIn) return false
      const { roles } = root.$store.state.user
      for (const role of roles) {
        if (role === 'Employee') return true
      }
      return false
    })
    const isXS = computed(() => root.$vuetify.breakpoint.name === 'xs')

    return {
      isEmployee,
      isXS
    }
  }
}
</script>
